import { PhoneNumberInput } from 'shared/components/phone-number-input/phone-number.component';
import { EdsStyleErrorMessage } from 'features/registration';

import 'features/registration/styles/registration-phone-number-input.scss';

export interface FormNumberInput {
  phoneNumber: string;
  handlePhoneNumber: (any) => void;
  onBlur: (any) => void;
  error: string;
}

export const RegistrationPhoneNumberInput = ({phoneNumber, handlePhoneNumber, onBlur, error}: FormNumberInput) => {

  return (
    <div className="registration-phone-number">
      <PhoneNumberInput
        id={error && 'phone-number-error'}
        phoneValue={phoneNumber}
        onPhoneChange={handlePhoneNumber}
        onBlur={onBlur}
      />
      {error && <EdsStyleErrorMessage errorMessage={error} />}
    </div>
  )
}
