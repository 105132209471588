import { lazy } from 'react';
const LazyLoginPage = lazy(() =>
	import(/* webpackChunkName: "auth", webpackPrefetch: true */ './login/login.component').then(({ LoginPage }) => ({ default: LoginPage }))
);
const LazyHomePage = lazy(() =>
	import(/* webpackChunkName: "default", webpackPrefetch: true */ './home/home.component').then(({ HomePage }) => ({ default: HomePage }))
);
const LazyFindLoadsPage = lazy(() =>
	import(/* webpackChunkName: "available-loads", webpackPrefetch: true */ './find-loads/find-loads.component').then(({ FindLoadsPage }) => ({ default: FindLoadsPage }))
);
const LazyTestPage = lazy(() =>
	import(/* webpackChunkName: "default", webpackPrefetch: true */ './test/test.component').then(({ TestPage }) => ({ default: TestPage }))
);
const LazyAvailableLoadDetailsPage = lazy(() =>
	import(/* webpackChunkName: "available-load-details", webpackPrefetch: true */ './find-loads/load-details/available-load-details.component').then(
		({ AvailableLoadDetailsPage }) => ({ default: AvailableLoadDetailsPage })
	)
);
const LazyMyLoadsPage = lazy(() =>
	import(/* webpackChunkName: "my-loads", webpackPrefetch: true */ './my-loads-v2/my-loads-page-container.component').then(({ MyLoadsPageContainer }) => ({ default: MyLoadsPageContainer }))
);
const LazyMyShipmentsPage = lazy(() =>
	import(/* webpackChunkName: "my-shipments", webpackPrefetch: true */ './my-shipments/my-shipments.component').then(({ MyShipmentsPage }) => ({
		default: MyShipmentsPage,
	}))
);
const LazyRegistrationPage = lazy(() =>
	import(/* webpackChunkName: "registration", webpackPrefetch: true */ './registration/registration.component').then(({ RegistrationPage }) => ({
		default: RegistrationPage,
	}))
);
const LazyRegistrationSuccessPage = lazy(() =>
	import(/* webpackChunkName: "registration", webpackPrefetch: true */ './registration/registration-success.component').then(({ RegistrationSuccessPage }) => ({
		default: RegistrationSuccessPage,
	}))
);
const LazyForgotUsernamePage = lazy(() =>
	import(/* webpackChunkName: "auth", webpackPrefetch: true */ './login/forgot-username/forgot-username.component').then(({ ForgotUsernamePage }) => ({
		default: ForgotUsernamePage,
	}))
);
const LazyForgotUsernameSuccessPage = lazy(() =>
	import(/* webpackChunkName: "auth", webpackPrefetch: true */ './login/forgot-username/forgot-username-success.component').then(({ ForgotUsernameSuccessPage }) => ({
		default: ForgotUsernameSuccessPage,
	}))
);
const LazyForgotPasswordPage = lazy(() =>
	import(/* webpackChunkName: "auth", webpackPrefetch: true */ './login/forgot-password/forgot-password.component').then(({ ForgotPasswordPage }) => ({
		default: ForgotPasswordPage,
	}))
);
const LazyForgotPasswordSuccessPage = lazy(() =>
	import(/* webpackChunkName: "auth", webpackPrefetch: true */ './login/forgot-password/forgot-password-success.component').then(({ ForgotPasswordSuccessPage }) => ({
		default: ForgotPasswordSuccessPage,
	}))
);
const LazyPostTrucksPage = lazy(() =>
	import(/* webpackChunkName: "post-trucks", webpackPrefetch: true */ './post-trucks/post-trucks.component').then(({ PostTrucksPage }) => ({ default: PostTrucksPage }))
);
const LazyResetPasswordPage = lazy(() =>
	import(/* webpackChunkName: "auth", webpackPrefetch: true */ './login/reset-password/index').then(({ ResetPasswordPage }) => ({
		default: ResetPasswordPage,
	}))
);
const LazyRatesPage = lazy(() =>
	import(/* webpackChunkName: "rates", webpackPrefetch: true */ './rates/rates.component').then(({ RatesPage }) => ({ default: RatesPage }))
);
const LazyTendersPage = lazy(() =>
	import(/* webpackChunkName: "tenders", webpackPrefetch: true */ './tenders/tenders.component').then(({ TendersPage }) => ({ default: TendersPage }))
);
const LazySpotBidsPage = lazy(() =>
	import(/* webpackChunkName: "spot-bids", webpackPrefetch: true */ './spot-bids/spot-bids.component').then(({ SpotBidsPage }) => ({ default: SpotBidsPage }))
);
const LazySpotBidsDetailsPage = lazy(() =>
	import(/* webpackChunkName: "spot-bid-details", webpackPrefetch: true */ './spot-bids/spot-bid-details.component').then(({ SpotBidsDetailsPage }) => ({
		default: SpotBidsDetailsPage,
	}))
);
const LazyOffersPage = lazy(() =>
	import(/* webpackChunkName: "offers", webpackPrefetch: true */ './offers/offers.component').then(({ OffersPage }) => ({ default: OffersPage }))
);

const LazyOffersVer2Page = lazy(() =>
	import(/* webpackChunkName: "available-loads-ver2", webpackPrefetch: true */ './offers-ver2/offers.component').then(({ OffersVer2Page }) => ({ default: OffersVer2Page }))
);
const LazyTMCReportsPage = lazy(() =>
	import(/* webpackChunkName: "default", webpackPrefetch: true */ './tmc-reports/tmc-reports.page').then(({ TMCReportsPage }) => ({ default: TMCReportsPage }))
);
const LazyDockManagerPage = lazy(() =>
	import(/* webpackChunkName: "default", webpackPrefetch: true */ './dock-manager/dock-manager.page').then(({ DockManagerPage }) => ({ default: DockManagerPage }))
);
const LazyContactUsPage = lazy(() =>
	import(/* webpackChunkName: "default", webpackPrefetch: true */ './contact-us/contact-us.component').then(({ ContactUsPage }) => ({ default: ContactUsPage }))
);
const LazyAccountsReceivablePage = lazy(() =>
	import(/* webpackChunkName: "accounts-receivable", webpackPrefetch: true */ './accounts-receivable/accounts-receivable.component').then(({ AccountsReceivablePage }) => ({
		default: AccountsReceivablePage,
	}))
);
const LazyPaymentsPage = lazy(() =>
	import(/* webpackChunkName: "payments", webpackPrefetch: true */ './payments/payments.component').then(({ PaymentsPage }) => ({
		default: PaymentsPage,
	}))
);
const LazyAccountsReceivableDetailsPage = lazy(() =>
	import(/* webpackChunkName: "accounts-receivable", webpackPrefetch: true */ './accounts-receivable/accounts-receivable-details.component').then(
		({ AccountsReceivableDetailsPage }) => ({ default: AccountsReceivableDetailsPage })
	)
);
const LazyAccountsReceivableInvoiceDetailsPage = lazy(() =>
	import(/* webpackChunkName: "accounts-receivable", webpackPrefetch: true */ './accounts-receivable/account-receivable-invoice-details.component').then(
		({ AccountsReceivableInvoiceDetailsPage }) => ({ default: AccountsReceivableInvoiceDetailsPage })
	)
);
const LazyAccountsReceivableCheckDetailsPage = lazy(() =>
	import(/* webpackChunkName: "accounts-receivable", webpackPrefetch: true */ './accounts-receivable/account-receivable-check-details.component').then(
		({ AccountsReceivableCheckDetailsPage }) => ({ default: AccountsReceivableCheckDetailsPage })
	)
);
const LazySettingsPage = lazy(() =>
	import(/* webpackChunkName: "default", webpackPrefetch: true */ './settings/settings.component').then(({ SettingsPage }) => ({ default: SettingsPage }))
);
const LazyClaimsPage = lazy(() =>
	import(/* webpackChunkName: "claims", webpackPrefetch: true */ './claims/claims.component').then(({ ClaimsPage }) => ({ default: ClaimsPage }))
);
const LazyManageInvoicesPage = lazy(() =>
	import(/* webpackChunkName: "manage-invoices", webpackPrefetch: true */ './manage-invoices/manage-invoices.component').then(({ ManageInvoicesPage }) => ({
		default: ManageInvoicesPage,
	}))
);
const LazyCreateInvoicePage = lazy(() =>
	import(/* webpackChunkName: "invoice-form", webpackPrefetch: true */ './manage-invoices/create/create-invoice.component').then(({ CreateInvoicePage }) => ({
		default: CreateInvoicePage,
	}))
);
const LazyViewInvoicePage = lazy(() =>
	import(/* webpackChunkName: "view-invoice", webpackPrefetch: true */ './manage-invoices/view/view-invoice.component').then(({ ViewInvoicePage }) => ({
		default: ViewInvoicePage,
	}))
);
const LazyEditInvoicePage = lazy(() =>
	import(/* webpackChunkName: "invoice-form", webpackPrefetch: true */ './manage-invoices/edit/edit-invoice.component').then(({ EditInvoicePage }) => ({
		default: EditInvoicePage,
	}))
);
const LazyPreferredLanesPage = lazy(() =>
	import(/* webpackChunkName: "preferred-lanes", webpackPrefetch: true */ './preferred-lanes/preferred-lanes.component').then(({ PreferredLanesPage }) => ({
		default: PreferredLanesPage,
	}))
);
const LazyLoadDetailsPage = lazy(() =>
	import(/* webpackChunkName: "my-load-details", webpackPrefetch: true */ './load-details/load-details.component').then(({ LoadDetailsPage }) => ({
		default: LoadDetailsPage,
	}))
);
const LazyShortRegistrationPage = lazy(() =>
	import(/* webpackChunkName: "registration", webpackPrefetch: true */ './registration/short-registration/short-registration.component').then(
		({ ShortRegistrationPage }) => ({ default: ShortRegistrationPage })
	)
);
const LazyShortRegistrationSuccessPage = lazy(() =>
	import(/* webpackChunkName: "registration", webpackPrefetch: true */ './registration/short-registration/short-registration-success.component').then(
		({ ShortRegistrationSuccessPage }) => ({ default: ShortRegistrationSuccessPage })
	)
);
const LazyShortRegistrationErrorPage = lazy(() =>
	import(/* webpackChunkName: "registration", webpackPrefetch: true */ './registration/short-registration/short-registration-error.component').then(
		({ ShortRegistrationErrorPage }) => ({ default: ShortRegistrationErrorPage })
	)
);
const LazyUnsubscribePage = lazy(() =>
	import(/* webpackChunkName: "default", webpackPrefetch: true */ './unsubscribe/unsubscribe.component').then(({ UnsubscribePage }) => ({ default: UnsubscribePage }))
);
const LazyUnsubscribeRegistrationPage = lazy(() =>
	import(/* webpackChunkName: "default", webpackPrefetch: true */ './unsubscribe/unsubscribe-registration.component').then(({ UnsubscribeRegistrationPage }) => ({
		default: UnsubscribeRegistrationPage,
	}))
);
const LazyUnsubscribeRegistrationErrorPage = lazy(() =>
	import(/* webpackChunkName: "default", webpackPrefetch: true */ './unsubscribe/unsubscribe-registration-error.component').then(
		({ UnsubscribeRegistrationErrorPage }) => ({ default: UnsubscribeRegistrationErrorPage })
	)
);
const LazyUnsubscribeErrorPage = lazy(() =>
	import(/* webpackChunkName: "default", webpackPrefetch: true */ './unsubscribe/unsubscribe-error.component').then(({ UnsubscribeErrorPage }) => ({
		default: UnsubscribeErrorPage,
	}))
);
const LazyDashboardPage = lazy(() =>
	import(/* webpackChunkName: "carrier-advantage", webpackPrefetch: true */ './carrier-advantage/dashboard/dashboard.component').then(({ Dashboard }) => ({
		default: Dashboard,
	}))
);
const LazyDashboardV2Page = lazy(() =>
	import(/* webpackChunkName: "carrier-advantage", webpackPrefetch: true */ './carrier-advantage-v2/dashboard/dashboard.component').then(({ Dashboard }) => ({
		default: Dashboard,
	}))
);
const LazyCarrierAdvantagePage = lazy(() =>
	import(/* webpackChunkName: "carrier-advantage", webpackPrefetch: true */ './carrier-advantage/carrier-programs/carrier-advantage-program.component').then(
		({ CarrierAdvantageProgram }) => ({ default: CarrierAdvantageProgram })
	)
);
const LazyCarrierAdvantageFaq = lazy(() =>
	import(/* webpackChunkName: "carrier-advantage", webpackPrefetch: true */ './carrier-advantage/carrier-advantage-faq/carrier-advantage-faq.component').then(
		({ CarrierAdvantageFaq }) => ({ default: CarrierAdvantageFaq })
	)
);
const LazySMSSubscription = lazy(() =>
	import(/* webpackChunkName: "default", webpackPrefetch: true */ './settings/sms.component').then(({ SMSSubscriptionPage }) => ({ default: SMSSubscriptionPage }))
);
const LazyFinancialTermsAndConditionsPage = lazy(() =>
	import(/* webpackChunkName: "default", webpackPrefetch: true */ './financial-terms-and-conditions/financial-terms-and-conditions.component').then(
		({ FinancialTermsAndConditionsPage }) => ({ default: FinancialTermsAndConditionsPage })
	)
);
const LazyCapLoadDetailsPage = lazy(() =>
	import(/* webpackChunkName: "carrier-advantage", webpackPrefetch: true */ './carrier-advantage/cap-load-details/cap-load-details.component').then(
		({ CapLoadDetailsPage: CapLoadDetails }) => ({ default: CapLoadDetails })
	)
);
const LazyCapLoadDetailsPageV2 = lazy(() =>
	import(/* webpackChunkName: "carrier-advantage", webpackPrefetch: true */ './carrier-advantage-v2/cap-load-details/cap-load-details.component').then(
		({ CapLoadDetailsPage: CapLoadDetails }) => ({ default: CapLoadDetails })
	)
);

const LazyOktaFaq = lazy(() =>
	import(/* webpackChunkName: "frequently-asked", webpackPrefetch: true */ './frequently-asked/okta-faq/okta-faq.component').then(({ OktaFaq }) => ({ default: OktaFaq }))
);
const LazySetOktaPreferredEmail = lazy(() =>
	import(/* webpackChunkName: "OktaPreferredEmailPage", webpackPrefetch: true */ '@features/okta/components/OktaPreferredEmailPage').then(({ SetOktaPreferredEmail }) => ({
		default: SetOktaPreferredEmail,
	}))
);
const LazyLoginOtp = lazy(() =>
	import(/* webpackChunkName: "LoginOtpComponent", webpackPrefetch: true */ '@features/security/auth/loginOtpComponent').then(({ LoginOtpComponent }) => ({
		default: LoginOtpComponent,
	}))
);

const LazyVerifyEmailAddress = lazy(() =>
	import(/* webpackChunkName: "VerifyEmailPage", webpackPrefetch: true */'@features/okta/components/RegistrationEmailVerification/VerifyEmailPage')
		.then(({ VerifyEmailPage: VerifyEmailPage }) => ({ default: VerifyEmailPage }))
);

const OktaSigninRedirectPage = lazy(() =>
	import(/* webpackChunkName: "VerifyEmailPage", webpackPrefetch: true */'@features/okta/components/OktaSigninRedirectPage')
		.then(({ OktaSigninRedirectPage }) => ({ default: OktaSigninRedirectPage }))
);

export namespace Pages {
	export const Login = LazyLoginPage;
	export const Home = LazyHomePage;
	export const FindLoads = LazyFindLoadsPage;
	export const Test = LazyTestPage;
	export const AvailableLoadDetails = LazyAvailableLoadDetailsPage;
	export const MyLoads = LazyMyLoadsPage;
	export const MyShipments = LazyMyShipmentsPage;
	export const RegistrationSuccess = LazyRegistrationSuccessPage;
	export const ForgotUsername = LazyForgotUsernamePage;
	export const ForgotUsernameSuccess = LazyForgotUsernameSuccessPage;
	export const ForgotPassword = LazyForgotPasswordPage;
	export const ForgotPasswordSuccess = LazyForgotPasswordSuccessPage;
	export const PostTrucks = LazyPostTrucksPage;
	export const ResetPassword = LazyResetPasswordPage;
	export const Rates = LazyRatesPage;
	export const Tenders = LazyTendersPage;
	export const SpotBids = LazySpotBidsPage;
	export const SpotBidsDetails = LazySpotBidsDetailsPage;
	export const Offers = LazyOffersPage;
	export const OffersVer2 = LazyOffersVer2Page;
	export const TMCReports = LazyTMCReportsPage;
	export const DockManager = LazyDockManagerPage;
	export const ContactUs = LazyContactUsPage;
	export const AccountsReceivableDetails = LazyAccountsReceivableDetailsPage;
	export const AccountsReceivableInvoiceDetails = LazyAccountsReceivableInvoiceDetailsPage;
	export const AccountsReceivableCheckDetails = LazyAccountsReceivableCheckDetailsPage;
	export const AccountsReceivable = LazyAccountsReceivablePage;
	export const Payments = LazyPaymentsPage;
	export const Settings = LazySettingsPage;
	export const FinancialTermsAndConditions = LazyFinancialTermsAndConditionsPage;
	export const Claims = LazyClaimsPage;
	export const ManageInvoices = LazyManageInvoicesPage;
	export const CreateInvoice = LazyCreateInvoicePage;
	export const ViewInvoice = LazyViewInvoicePage;
	export const EditInvoice = LazyEditInvoicePage;
	export const PreferredLanes = LazyPreferredLanesPage;
	export const LoadDetails = LazyLoadDetailsPage;
	export const Registration = LazyRegistrationPage;
	export const ShortRegistration = LazyShortRegistrationPage;
	export const ShortRegistrationSuccess = LazyShortRegistrationSuccessPage;
	export const ShortRegistrationError = LazyShortRegistrationErrorPage;
	export const Unsubscribe = LazyUnsubscribePage;
	export const UnsubscribeRegistration = LazyUnsubscribeRegistrationPage;
	export const UnsubscribeRegistrationError = LazyUnsubscribeRegistrationErrorPage;
	export const UnsubscribeError = LazyUnsubscribeErrorPage;
	export const Dashboard = LazyDashboardPage;
	export const DashboardV2 = LazyDashboardV2Page;
	export const CarrierAdvantageProgram = LazyCarrierAdvantagePage;
	export const CarrierAdvantageFaq = LazyCarrierAdvantageFaq;
	export const SMSSubscription = LazySMSSubscription;
	export const CapLoadDetails = LazyCapLoadDetailsPage;
	export const CapLoadDetailsV2 = LazyCapLoadDetailsPageV2;
	export const OktaFaq = LazyOktaFaq;
	export const SetOktaPreferredEmail = LazySetOktaPreferredEmail;
	export const VerifyEmailAddress = LazyVerifyEmailAddress;
	export const LoginOtpComponent = LazyLoginOtp;
	export const OktaSigninRedirect = OktaSigninRedirectPage;
}